.pageContainer{
    background-color: white;
    height: 100%;
    width:  100%;
    justify-content: center;
    
  }
  
  .headerContainer{
    height: fit-content;
    width: 100%;
  }
/* 
  .containerTitle{
    background: -webkit-linear-gradient(left,#2bc1f6,#59B1F2);
  } */

  .images{
      max-width: 100%;
      max-height: auto;
      
  }

  .midContent{
    display: flex;
    width: 100%;
    height: 400px;
    color: white;
    margin-top: -10px;
    overflow: hidden;
    padding: 50px;
    background: -webkit-linear-gradient(left,#2bc1f6,#138ee9);
  }

  .midContentImage img{
    width: 500px;
    height: 300px;
  }


  .midContent h2{
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .cardDeckProduct{
      margin: 10px 10px 10px 10px;
  }

  .cardDeckVisionMission{
    margin: 10px 10px 10px 10px;
  }
  .containerVisionMission{
      max-width: 90%;
      height: 100%;
      width: 100%;
      background-color: #0057ff;
  }

  .textVisionMission{
      
      margin: 30px;
      font-weight: bold ;
  }
  .textSeeAllProduct{
    float: right;
    margin-right: 10px;
  }

  .footer{
    background-color: #0057ff;
    height: fit-content;
    width: 100%;
    overflow: hidden;
    color: white;
    display: flex;
    background: -webkit-linear-gradient(left,#2bc1f6,#138ee9);
              }
    .footer img{
      margin: 20px;
    }      
    .footerAddress{
      font-weight: bold;
    
    }    
    .footerCopyright{
    font-weight: bold;
 
  }

  .footerCompanyName{
    font-weight: bold;
    margin-left: 80%;
    
  }
  .imageCompany{
    width: 550px;
    height: 400px;
    margin-top: 10px;
  }

  @media screen and (min-width: 411px) {
    .imageCompany {
      width: 700px;
      height: 350px;
      margin-left: 18px;
      margin-top: 10px;
      
    }
    .tentangKami{
      align-items: center;
      justify-content: center;
      justify-items: center;
    }

    .imageCustomerReward{
      padding-top: 50px;
      margin-top:0px;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      height: 80%;
      display: -moz-deck;
    }

    .customerReward h5{
      letter-spacing: 3px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .footerAddress{
      text-align: center;
    }
    .misi h5{
      text-align: left;
     }

  }

  @media screen and (min-width: 360px) {
    .imageCustomerReward{
      margin-top:0px;
      width: 70%;
      height: 80%;
    }

    .customerReward h5{
      margin-left: 10px;
      margin-right: 10px;
    }

    .footerAddress{
      text-align: center;
    }


    .footer img{
      margin-left: 40px;
    }

    .misi h5{
      text-align: justify
     }
   
  }



  @media screen and (min-width: 300px) {
    .imageCompany {
      width: 360px;
      height: 200px;
      margin-top: 30px;
    }
    .misi h5{
      text-align: justify
     }
  }

  @media screen and (max-width: 320px) {
    .misi h5{
      text-align: justify
      
     }
    .imageCompany {
      width: 370px;
      height: 200px;
      margin-top: 30px;
    }
    .imageCustomerReward{
      margin-top:0px;
      width: 70%;
      height: 80%;
    }

    .customerReward h5{
      margin-left: 10px;
      margin-right: 10px;
    }

    .footer img{
      width: 90%;
      height: 70%;
    }
  }

  @media screen and (min-width: 1080px) {

  
  }


  @media screen and (min-width: 768px) {
    .imageCompany {
      width: 700px;
      height: 400px;
      margin-left: -6px;
    }
    .containerTentangKami h5{
      margin-top: 20px;
      margin-left: -100px;
      letter-spacing: 3px;
    }
    .containerTentangKami h2{
      margin-top: 60px;
      margin-left: -100px;
    }

    .gallery{
      margin-top: -150px;
    }

    .footerCopyright{
      margin-top: 94px;
    }

    .offset-md-1 {
      margin-top: 26px;
  }

  }

  @media screen and (min-width: 992px) {
    .imageCompany {
      width: 600px;
      height: 400px;
      margin-left: -20px;
    }
    .containerTentangKami h5{
      margin-left: 50px;
    }
    .containerTentangKami h2{
      margin-left: 36px;
    }


  }



  @media screen and (min-width: 1024px){
    .imageCompany {
      width: 160%;
      height: 90%;
      margin-left:-10%
    }
    .containerTentangKami h5{
      margin-left: 140px;
    }
    .containerTentangKami h2{
      margin-left: 140px;
    }

    .imageCustomerReward{
      margin-top:-60px;
     
    }

    .footerCopyright{
      margin-top: 10.4%;
      margin-left: 5%;
    }

    .footerAddress{
      margin-top: 2%;
      text-align: justify;
    }


  }

  .containerFooter{
    width: 100%;
  }

  
  @media screen and (min-width: 1024px) and (min-height:1366){
    .imageCompany {
      width: 400px;
      height: 300px;
   
      margin-left: -60px;
    }
    .containerTentangKami h5{
      margin-left: 100px;
  
    }
    .containerTentangKami h2{
      margin-left: 100px;
    }
    .gallery{
      margin-top: -130px;
    }
  }

  .tentangKami{
    width: 100%;
    max-width: 100%;
    background: -webkit-linear-gradient(left,#2bc1f6,#138ee9);
    margin-top: -2px;
  }


  .containerTentangKami h2 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 20px;  
    margin-top: 10px;
    color: white;
  }

  .containerTentangKami h5 {
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 150%;
    font-weight: 100;
    padding-top: 0;
    text-align: justify;
    color: white;
  }

  .visi{
    padding: 20px;
  }

  .visi h2{
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .spaceKosongVisi{
    position: relative;
    width: 80px;
    height: 4px;
    background: #138ee9;
    margin-left: -36px;
    margin-top: -40px;
    bottom: -18px;
    left: 50%;
    margin-bottom: 50px;
  }

  .visi h5{
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 30px;
    letter-spacing: 3px;

  }

  .misi{
    padding: 20px;
  }

  .misi h2{
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: -20px;
  }

  .spaceKosongMisi{
    position: relative;
    width: 80px;
    height: 4px;
    background: #138ee9;
    margin-left: -40px;
    margin-top: -40px;
    bottom: -20px;
    left: 50%;
  }

  .misi h5{
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 30px;
    letter-spacing: 3px;

  }

  .previewProductTitle{
    width: 100%;
    height: fit-content;
  }

.produkKamiContainer{
  background: -webkit-linear-gradient(left,#2bc1f6,#138ee9);
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: -10px
}

  .previewProduct h2{
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 50px;
    color: white;
    
  }
  .cardPreviewProduct{
    margin-top: 50px;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 30px;
    
  }
  .spaceKosongPreviewProduct{
    position: relative;
    width: 100px;
    height: 4px;
    background: #138ee9;
    margin-top: -40px;
    bottom: -20px;
    left: 38%;
  }

.cardPreviewProduct{
  align-content: center;
  justify-content: center;
  display: flex;
}

.customerRewardContainer{
  margin-top: 50px;
}
  
.galleryContainer{
  margin-top: 200px;
}
  