.navigationBar{
      position: relative;
}
.color-nav{
   background: -webkit-linear-gradient(left,#2bc1f6,#138ee9); 
   width: 100%;  
}
#responsive-navbar-nav {
   justify-content: flex-end;
   margin-right: 50px;
}

@media screen and (min-width: 1024px){
   .color-nav{
   background: -webkit-linear-gradient(left,#2bc1f6,#138ee9); 
   width: 100%;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 20;

   }
 }
body {
    font-family: 'Quicksand', sans-serif;
    font-weight: 300;
   }
   
   h1, h2, h3, h4, h5, h6 {
    font-weight: 800;
    text-decoration: none;
   }

 .navbarItems {
    display: flex;     
    align-items: center;
 }  
 .navbarItems img{
    height: 24px;
    width: 24px;
    margin-bottom: 4px;
    margin-right: 4px;
    margin-left: 4px;
    
 }

 @media screen and (min-width: 1024px){
   .brand1{
      margin-left: 38px;
      margin-bottom: -10px;
   }

   .brand2{
      margin-left: 40px;
      margin-bottom: -10px;
   }
 }


 .navigationBar{

     background-color: #0057ff;

 }