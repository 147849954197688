.footer3{
    background: -webkit-linear-gradient(left,#2bc1f6,#138ee9);
}

.companyImage{
    margin-top: 20px;
    width:100%;
    height:100%;
    margin-left: auto;
    margin-right: auto;
}

.footerAddress{
    height:100%;
    margin-top: 20px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto; 
    color: #ffffff;
    margin-bottom: 30px;
    text-align: center;
}
.footerNav{
   
    font-size: 20px;
    margin-top: 20px;

}
.footerCopyright{
    font-size: 20px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: center
}