.headerContainer{
    height: fit-content;
    width: 100%;
  }

  .productGreeting{
    margin-bottom: 30px;
    font-size: 24px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: center;
    margin-top: 16px;
  }

 
  .pageContainer{
    background-color: white;
    height: 100%;
    width:  100%;
    justify-content: center;
    overflow: hidden;
  }

@media screen and (min-width: 1024px){
  .containerTitle{
  background: -webkit-linear-gradient(left,#2bc1f6,#59B1F2);
  height: 200px;
  width: 100%;  
  margin-top: 60px;
  padding-top: 10px;
  }

  .containerTitle h2{
    background: -webkit-linear-gradient(left,#2bc1f6,#59B1F2);
    height: 200px;
    width: 100%;  
    margin-top: -10px;
    padding-top: 10px;
    }
}

.containerTitle{
  background: -webkit-linear-gradient(left,#2bc1f6,#59B1F2);
  height: 200px;
  width: 100%;  
 
}
.containerTitle h2{
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 70px;
    font-weight: 700;
    margin-bottom: 20px;
    color: white;
}

.firstProductList{
 padding: 20px;
}

.secondProductList{
  padding: 20px;
 }

