.cardBody{
    background-color: khaki;
    display: flex;
    float: right;
}

.cardContainer{
    margin-top: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
}

