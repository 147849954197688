.tentangKamiContainer{
    width: 100%;
    display: flex;
   justify-content: center;
   align-content: center;
   flex-direction: column;
    /* background: -webkit-linear-gradient(left,#2bc1f6,#59B1F2); */
   margin-top: -2px;
   padding-bottom: 30px;

}

.tentangKamiImg{
    justify-content: center;
    display: flex;
}


.imageCompany2{
    height: 100%;
    width: 100%;
    max-width: 100%;
  
}

.tentangKamiText{
    line-height: 2;
    text-align: justify;
    letter-spacing: 2;
    margin-left: 20px;
    margin-right: 20px;
    
    
}

.tentangKamiText h2{
    color: white;
}

.tentangKamiText h5{
    /* color: white; */
    font-size: 20px;
    line-height: 140%
}

@media screen and (min-width: 1024px){
    .tentangKamiText{
       margin-top: -50px;
    }
    .imageCompany2{
        height: 50%;
        width: 50%
    }

}

@media screen and (min-width: 1080px){

}
