#responsive-navbar-nav{
    justify-content: flex-end;
}

.headerContainer{
    height: fit-content;
    width: 100%;
  }
  .pageContainer{
    background-color: white;
    height: 100%;
    width:  100%;
    justify-content: center;
    overflow: hidden;
  }

  .h1ContactPerson{
    height: 100%;
    width:  100%;
    justify-content: center;
  }

  .contactGreetings h5{
      margin-bottom: 30px;
        font-size: 24px;
        margin-left: 16px;
        margin-right: 16px;
      text-align: center;
  }
  @media screen and (min-width: 1024px){
  .firstProductList{
    margin-top: 40px;
  }
}


  
@media screen and (min-width: 1024px){
  .firstProductList{
    margin-top: 40px;
  }
}

/* @media screen and (min-width: 1024px){
  .containerTitle{
  background: -webkit-linear-gradient(left,#2bc1f6,#59B1F2);
  height: 200px;
  width: 100%;  
  margin-top: 60px;
  padding-top: 10px;
  }
} */

.containerTitleContact{
  background: -webkit-linear-gradient(left,#2bc1f6,#59B1F2);
  
}
.containerTitleContact h2{
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 70px;
    font-weight: 700;
    margin-bottom: 20px;
    /* margin-top: 60px; */
    color: white;
}



@media screen and (min-width: 1024px){
    .containerTitleContact{
        background: -webkit-linear-gradient(left,#2bc1f6,#59B1F2);
        height: 200px;
        width: 100%;  
        margin-top: 65px;
        padding-top: 10px;
        }
    .containerTitleContact h2{
       margin-top: 40px; 
    }    
    .contactGreetings h5{
        margin-top: 20px;
    }
  }



